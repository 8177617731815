.App {
  background-color: whitesmoke;
  display: flex;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  overflow: auto;
}

.ck-logo {
  height: 300px;
  object-fit: cover;
  position: relative;
  bottom: 40px;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-center-all {
  justify-content: center;
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}

.flex-end {
  justify-content: flex-end;
}

.flex-grow {
  flex-grow: 1;
}

.flex-start {
  justify-content: flex-start;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.lightbox {
  background-color: whitesmoke;
  border: none;
  border: 2px solid gray;
  border-radius: 8px;
  left: 50%;
  max-height: 85vh;
  outline: none;
  overflow: auto;
  min-width: 320px;
  padding: 25px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.link:hover {
  cursor: pointer;
}

.overflow-auto {
  overflow: auto;
}

.relative {
  position: relative;
}

.table-left-col {
  width: 20%;
  text-align: center;
}

.table-middle-col {
  width: 35%;
}

.service-section-table {
  width: 90%;
  margin-left: 5%;
}

.sunday-service-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 30%;
}

.sunday-service-card {
  margin-left: 5%;
  min-width: 650px;
}

.sunday-service-card-section {
  min-height: 70px;
  padding-left: 10px;
  padding-right: 10px;
}

.page-container {
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;
}

.sunday-server-table {
  border-collapse: collapse;
  text-align: center;
  border-bottom: 1px solid silver;
}

.non-editable-cell {
  border-left: 1px solid silver;
  border-right: 1px solid silver;
}

.editable-cell {
  border-left: 1px solid silver;
  border-right: 1px solid silver;
}
.editable-cell:hover {
  background-color: rgba(51, 116, 186, 0.1);
  cursor: pointer;
}

.cell {
  border-left: 1px solid silver;
  border-right: 1px solid silver;
}

.header-cell {
  border: 1px solid silver;
}

@media only screen and (max-device-width: 450px) {
  .lightbox {
    max-width: 80%;
  }

  .sunday-service-container {
    display: flex;
    flex-direction: column;
  }

  .sunday-service-card {
    margin-top: 10px;
    margin-left: 0;
    min-width: 400px;
  }

  .sunday-service-card-section {
    min-height: 70px;
    padding-left: 0;
    padding-right: 0;
  }

  .service-section-table {
    width: 100%;
    margin-left: 0;
  }

  .table-left-col {
    width: 20%;
    text-align: center;
  }

  .table-middle-col {
    width: 35%;
  }
}
